import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import createPersistedState from "vuex-persistedstate";

import BacklogRecord from "../models/BacklogRecord";
import SprintRecord from "../models/SprintRecord";
import SprintContributorRecord from "../models/SprintContributorRecord";
import Period from "../models/Period";

// Store module
import BacklogRecordModule from "./modules/backlogRecord";
import SprintRecordModule from "./modules/sprintRecord";
import SprintContributorRecordModule from "./modules/sprintContributorRecord";
import PeriodModule from "./modules/period";
import AsanaCredentialsModule from "./modules/asanaCredentials";

// Vuex OIDC
import { vuexOidcCreateStoreModule } from "vuex-oidc";
import { oidcSettings } from "@/setup/OIDCSettings";

// Client
import { initReportingClientAuth } from "@/setup/socioGrpcClient";

Vue.use(Vuex);

const database = new VuexORM.Database();

database.register(BacklogRecord);
database.register(SprintRecord);
database.register(SprintContributorRecord);
database.register(Period);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    oidc: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        namespaced: true,
        dispatchEventsOnWindow: true
      },
      {
        userLoaded: user => {
          initReportingClientAuth(user.access_token);
        }
      }
    ),
    asanaCredentials: AsanaCredentialsModule,
    backlogRecord: BacklogRecordModule,
    sprintRecord: SprintRecordModule,
    sprintContributorRecord: SprintContributorRecordModule,
    period: PeriodModule
  },
  plugins: [
    VuexORM.install(database),
    createPersistedState({
      paths: [
        ...["access_token", "refresh_token"].map(
          key => `asanaCredentials.${key}`
        )
      ]
    })
  ]
});
