const TeamDisplay = () =>
  import(/* webpackChunkName: "TeamDisplay" */ "@/components/TeamDisplay");

const TeamDashboard = () =>
  import(/* webpackChunkName: "TeamDashboard" */ "@/components/TeamDashboard");

export default [
  {
    path: "",
    name: "Home",
    redirect: { name: "TeamDisplay" }
  },
  {
    path: "teamdisplay",
    name: "TeamDisplay",
    component: TeamDisplay
  },
  {
    path: "teamdashboard",
    name: "TeamDashboard",
    component: TeamDashboard
  }
];
