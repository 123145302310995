<template>
  <VApp>
    <RouterView />
  </VApp>
</template>

<script>
export default {
  name: "App"
};
</script>
