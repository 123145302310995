import { socioGrpcClient } from "@/setup/socioGrpcClient";

import SprintContributorRecord from "@/models/SprintContributorRecord";

const actions = {
  async createSprintContributorRecord(context, ContributorData, metadata = {}) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.reporting_back.backlogrecords.DevSprintContributorRequest,
      ContributorData
    );
    const response = await socioGrpcClient.reporting_back.backlogrecords.DevSprintContributorControllerPromiseClient.create(
      request,
      metadata
    );
    return await SprintContributorRecord.insert({
      data: response.toObject()
    });
  },
  async fetchSprintContributorRecords(context, metadata) {
    SprintContributorRecord.deleteAll();
    const request = new socioGrpcClient.reporting_back.backlogrecords.DevSprintContributorListRequest();

    const response = await socioGrpcClient.reporting_back.backlogrecords.DevSprintContributorControllerPromiseClient.list(
      request,
      metadata
    );

    const resultsList = await response.toObject().resultsList;
    return await SprintContributorRecord.insert({
      data: resultsList
    });
  },
  async updateSprintContributorRecord(context, ContributorData) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.reporting_back.backlogrecords.DevSprintContributorRequest,
      ContributorData
    );
    const response = await socioGrpcClient.reporting_back.backlogrecords.DevSprintContributorControllerPromiseClient.update(
      request,
      {}
    );
    return SprintContributorRecord.update({
      data: response.toObject()
    });
  }
};

export default {
  namespaced: true,
  actions
};
