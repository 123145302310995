import frVuetify from "vuetify/es5/locale/fr";

export default {
  // These are the default vuetify translations
  ...frVuetify,
  // Here you write your custom vuetify translations that will override the default ones
  dataTable: {
    itemsPerPageText: "Éléments par page :",
    ariaLabel: {
      sortDescending: "Tri décroissant.",
      sortAscending: "Tri croissant.",
      sortNone: "Non trié.",
      activateNone: "Activer pour supprimer le tri.",
      activateDescending: "Activer pour trier par ordre décroissant.",
      activateAscending: "Activer pour trier par ordre croissant."
    },
    sortBy: "Trier par"
  },
  dataIterator: {
    pageText: "{0}-{1} sur {2} au total",
    noResultsText: "Aucun résultat",
    loadingText: "Chargement..."
  },
  dataFooter: {
    pageText: "{0}-{1} sur {2} au total",
    itemsPerPageText: "Élements par page :",
    itemsPerPageAll: "Tout",
    nextPage: "Page suivante",
    prevPage: "Page précédente",
    firstPage: "Première page",
    lastPage: "Dernière page"
  },
  pagination: {
    ariaLabel: {
      next: "Suivant",
      previous: "Précédent",
      page: "Page",
      currentPage: "Page courrante",
      wrapper: "Wrapper"
    }
  },
  noDataText: "Aucun élément"
};
