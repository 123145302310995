import { Model } from "@vuex-orm/core";

export default class SprintRecord extends Model {
  static entity = "sprintRecord";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      name: this.string(""),
      goal: this.string(""),
      contributors: this.attr([])
    };
  }
}
