import { Model } from "@vuex-orm/core";
import { socioGrpcClient } from "@/setup/socioGrpcClient";

export default class Period extends Model {
  static entity = "period";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      backlogrecord: this.string(""),
      startDate: this.string(""),
      endDate: this.string(""),
      velocity: this.number(0)
    };
  }
  static fetchPeriods = async filters => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }
      const request = new socioGrpcClient.reporting_back.backlogrecords.PeriodListRequest();

      const response = await socioGrpcClient.reporting_back.backlogrecords.PeriodControllerPromiseClient.list(
        request,
        metadata
      );
      const resultsList = response.toObject().resultsList;
      if (!resultsList) {
        return null;
      }
      this.insertOrUpdate({
        data: resultsList
      });
      return resultsList;
    } catch (error) {
      console.err("error:", error);
      return null;
    }
  };
  static createPeriod = async periodData => {
    try {
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.reporting_back.backlogrecords.PeriodRequest,
        periodData
      );
      const response = await socioGrpcClient.reporting_back.backlogrecords.PeriodControllerPromiseClient.create(
        request,
        {}
      );
      this.insert({
        data: response.toObject()
      });
      return response.toObject();
    } catch (error) {
      console.err("error:", error);
      return null;
    }
  };
  static deletePeriod = async periodUuid => {
    try {
      const request = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.reporting_back.backlogrecords.PeriodDestroyRequest,
        {
          uuid: periodUuid
        }
      );
      await socioGrpcClient.reporting_back.backlogrecords.PeriodControllerPromiseClient.destroy(
        request,
        {}
      );
      this.delete(periodUuid);
    } catch (error) {
      console.err("error:", error);
      return null;
    }
  };
}
