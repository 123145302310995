import $vuetify from "./fr_vuetify";

export default {
  // our global french translations
  global: {
    hello: "Bonjour de global message"
  },
  // our french vuetify translations
  $vuetify
};
