import { Model } from "@vuex-orm/core";

export default class SprintContributorRecord extends Model {
  static entity = "sprintContributorRecord";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      firstName: this.string(""),
      lastName: this.string(""),
      asanaId: this.string(""),
      sprintRecord: this.string(""),
      presencePercentage: this.number(0),
      feeling: this.string("")
    };
  }
}
