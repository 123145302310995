import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import "./setup/chartDayjsAdapter";
import SocioVueComponents from "@socotec.io/socio-vue-components";

Vue.config.productionTip = false;
Vue.use(SocioVueComponents);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
