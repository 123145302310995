import $vuetify from "./en_vuetify";

export default {
  // our global english translations
  global: {
    hello: "Hello from global message"
  },
  // our english vuetify translations
  $vuetify
};
