import enVuetify from "vuetify/es5/locale/en";

export default {
  // These are the default vuetify translations
  ...enVuetify,
  // Here you write your custom vuetify translations that will override the default ones
  dataTable: {
    itemsPerPageText: "Rows per page:",
    ariaLabel: {
      sortDescending: ": Sorted descending. Activate to remove sorting.",
      sortAscending: ": Sorted ascending. Activate to sort descending.",
      sortNone: ": Not sorted. Activate to sort ascending.",
      activateNone: "Activate to remove sorting.",
      activateDescending: "Activate to sort descending.",
      activateAscending: "Activate to sort ascending."
    },
    sortBy: "Sort by"
  },
  dataIterator: {
    pageText: "{0}-{1} of {2}",
    noResultsText: "No matching records found",
    loadingText: "Loading items..."
  },
  pagination: {
    ariaLabel: {
      next: "Next",
      previous: "Previous",
      page: "Page",
      currentPage: "Current page",
      wrapper: "Wrapper"
    }
  }
};
