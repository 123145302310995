import { socioGrpcClient } from "@/setup/socioGrpcClient";

import BacklogRecord from "@/models/BacklogRecord";

const state = {
  backlogRecordsCount: 0
};

const getters = {
  getBacklogRecordsCount: state => {
    return state.backlogRecordsCount;
  }
};

const actions = {
  async createBacklogRecord(context, backlogRecordData, metadata = {}) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.reporting_back.backlogrecords.BacklogRecordRequest,
      backlogRecordData
    );
    const response = await socioGrpcClient.reporting_back.backlogrecords.BacklogRecordControllerPromiseClient.create(
      request,
      metadata
    );
    const backlogRecord = await BacklogRecord.insert({
      data: response.toObject()
    });
    return backlogRecord;
  },
  async fetchBacklogRecords({ commit }, metadata) {
    const request = new socioGrpcClient.reporting_back.backlogrecords.BacklogRecordListRequest();

    const response = await socioGrpcClient.reporting_back.backlogrecords.BacklogRecordControllerPromiseClient.list(
      request,
      metadata
    );

    commit("UPDATE_BACKLOG_RECORDS_COUNT", response.getCount());
    const resultsList = response.toObject().resultsList;
    return await BacklogRecord.insert({
      data: resultsList
    });
  },
  async updateBacklogRecord(context, backlogRecordData, metadata = {}) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.reporting_back.backlogrecords.BacklogRecordRequest,
      backlogRecordData
    );
    const response = await socioGrpcClient.reporting_back.backlogrecords.BacklogRecordControllerPromiseClient.update(
      request,
      metadata
    );
    const backlogRecord = await BacklogRecord.update({
      where: backlogRecordData.uuid,
      data: {
        ...response.toObject()
      }
    });
    return backlogRecord;
  }
};

const mutations = {
  UPDATE_BACKLOG_RECORDS_COUNT: function(state, newTotal) {
    state.backlogRecordsCount = newTotal;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
