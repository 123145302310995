import { Model } from "@vuex-orm/core";

export default class BacklogRecord extends Model {
  static entity = "backlogRecord";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      projectName: this.string(""),
      backlogName: this.string(""),
      projectId: this.string(""),
      backlogId: this.string(""),
      totalStoryPoints: this.number(0),
      sprintDuration: this.number(0)
    };
  }
}
