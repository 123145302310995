import SocioGrpcApi from "@socotec.io/socio-grpc-api";

const socioGrpcClient = new SocioGrpcApi(
  process.env.VUE_APP_SOCIO_GRPC_API_ENV
);

const initReportingClientAuth = accessToken => {
  socioGrpcClient.accessToken = accessToken;
};

export default socioGrpcClient;
export { socioGrpcClient, initReportingClientAuth };
