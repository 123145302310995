const state = () => ({
  access_token: "",
  data: {},
  expires_in: 3600,
  refresh_token: "",
  token_type: "bearer"
});

const getters = {
  getAccessToken: state => {
    return state.access_token;
  },

  getRefreshToken: state => {
    return state.refresh_token;
  },
  getData: state => {
    return state.data;
  }
};

const mutations = {
  setAsanaCredentials: (
    state,
    { accessToken, data, expiresIn, refreshToken, tokenType }
  ) => {
    state.access_token = accessToken;
    state.data = data;
    state.expires_in = expiresIn;
    state.refresh_token = refreshToken;
    state.token_type = tokenType;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
