import { socioGrpcClient } from "@/setup/socioGrpcClient";

import SprintRecord from "@/models/SprintRecord";

const getters = {
  async getSprintRecords() {
    await actions.fetchSprintRecords();
    return await SprintRecord.all();
  }
};

const actions = {
  async createSprintRecord(context, SprintData, metadata = {}) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.reporting_back.backlogrecords.SprintRecordRequest,
      SprintData
    );
    const response = await socioGrpcClient.reporting_back.backlogrecords.SprintRecordControllerPromiseClient.create(
      request,
      metadata
    );
    return await SprintRecord.insert({
      data: response.toObject()
    });
  },
  async fetchSprintRecords() {
    await SprintRecord.deleteAll();
    const request = new socioGrpcClient.reporting_back.backlogrecords.SprintRecordListRequest();

    const response = await socioGrpcClient.reporting_back.backlogrecords.SprintRecordControllerPromiseClient.list(
      request,
      {}
    );

    const resultsList = await response.toObject().resultsList;
    return await SprintRecord.insert({
      data: resultsList
    });
  },
  async updateSprintRecord(context, SprintData) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.reporting_back.backlogrecords.SprintRecordRequest,
      SprintData
    );
    const response = await socioGrpcClient.reporting_back.backlogrecords.SprintRecordControllerPromiseClient.update(
      request,
      {}
    );
    return await SprintRecord.update(response.toObject());
  }
};

export default {
  namespaced: true,
  getters,
  actions
};
