export const oidcSettings = {
  authority: process.env.VUE_APP_SSO_URL,
  clientId: process.env.VUE_APP_SSO_CLIENT_ID_FRONT,
  redirectUri: `${window.location.origin}/oidc-callback`,
  postLogoutRedirectUri: `${window.location.origin}`,
  responseType: "code",
  scope: "openid profile email",
  automaticSilentRenew: true,
  validateSubOnSilentRenew: true,
  monitorSession: false,
  revokeAccessTokenOnSignout: true
};
